
import { ListCategories } from "@/services/v1/admin/learning/categories/types";
import { ListTopic } from "@/services/v1/admin/learning/topic/types";
import { gpf } from "@/utils/global-functions";
import { popupStatus } from "@/utils/popup/status";
import {
  computed,
  defineAsyncComponent,
  defineComponent,
  nextTick,
  reactive,
  ref,
  watch,
} from "vue";

const ModalTemplate = defineAsyncComponent(
  () => import(`@/components/modal-template/Main.vue`)
);

const TextField = defineAsyncComponent(
  () => import(`@/global-components/input/text-field/Main.vue`)
);

const TextArea = defineAsyncComponent(
  () => import(`@/global-components/input/textarea/Main.vue`)
);

const SingleImageUpload = defineAsyncComponent(
  () => import(`@/global-components/input/single-image-upload/Main.vue`)
);

const SingleFileUpload = defineAsyncComponent(
  () => import(`@/global-components/input/single-file-upload/Main.vue`)
);

const Autocomplete = defineAsyncComponent(
  () => import(`@/global-components/input/autocomplete/Main.vue`)
);

const RadioGroup = defineAsyncComponent(
  () => import(`@/global-components/input/radio-group/Main.vue`)
);

const servicesV1AdminLearning = async () =>
  import(`@/services/v1/admin/learning`);

const servicesV1AdminLearningCategories = async () =>
  import(`@/services/v1/admin/learning/categories`);

const servicesV1AdminLearningTopic = async () =>
  import(`@/services/v1/admin/learning/topic`);

export default defineComponent({
  props: {
    showFormMateri: {
      type: Boolean,
      default: false,
    },
    materiId: {
      type: Number,
      default: 0,
    },
    materiSlug: {
      type: String,
      default: "",
    },
  },
  components: {
    ModalTemplate,
    TextField,
    TextArea,
    SingleImageUpload,
    SingleFileUpload,
    Autocomplete,
    RadioGroup,
  },
  setup(props, { emit }) {
    // START === Constant variable ===============================================
    const modalId = "modal-form-materi";
    const materiId = computed(() => props.materiId);
    const materiSlug = computed(() => props.materiSlug);
    const isEditPage = computed(() => !!materiSlug.value);

    const titleForm = computed(() =>
      isEditPage.value ? "Form Edit Materi" : "Form Add Materi"
    );

    const filterTypes = [
      {
        text: "e-Book",
        value: "ebook",
      },
      {
        text: "Video",
        value: "video",
      },
    ];

    const listStatus = [
      {
        text: "Active",
        value: "1",
      },
      {
        text: "Inactive",
        value: "0",
      },
    ];

    // END === Constant variable =================================================

    // START === for handle show hide modal form materi ====================================
    const hideFormMateri = () => {
      resetForm();

      emit("update:show-form-materi", false);
      emit("update:materi-id", 0);
      emit("update:materi-slug", "");
    };

    watch(
      () => props.showFormMateri,
      (newVal) => {
        if (newVal) gpf.showModal(`#${modalId}`);
        gpf.onHideModal(`#${modalId}`, hideFormMateri);

        // fetch initial another data
        getCategories();
        // getTopics();
      }
    );
    // END === for handle show hide modal form materi ======================================

    // START === Form Data =================================================================

    const form = reactive({
      title: "",
      banner: {
        file: "",
        fileUrl: "",
      },
      content: {
        file: "",
        fileUrl: "",
      },
      contentVideo: "",
      types: "ebook",
      isActive: "0",
      category: "0",
      topic: "0",
    });

    const resetForm = () => {
      form.title = "";
      form.banner = {
        file: "",
        fileUrl: "",
      };
      form.content = {
        file: "",
        fileUrl: "",
      };
      form.contentVideo = "";
      form.types = "ebook";
      form.isActive = "0";
      form.category = "0";
      form.topic = "0";
    };

    // END === Form Data =================================================================

    // START === Function for get list categories ============================================
    const categories = ref([] as any[]);
    const getCategories = async () => {
      const {
        getAdminLearningCategories,
      } = await servicesV1AdminLearningCategories();

      try {
        const hit = await getAdminLearningCategories();

        const resData = hit?.response;

        categories.value = resData.map((category: ListCategories) => ({
          ...category,
          text: category.title,
          value: category.id.toString(),
        }));
      } catch (err: any) {
        console.error("err getCategories ", err, err?.response);
      }
    };

    const prettierTopicsFromCategory = computed(() => {
      const categoryId = form.category;
      const listTopic = categories.value.find(
        ({ value }) => value === categoryId
      );

      return (
        listTopic?.topics?.map((topic: any) => ({
          text: topic?.title,
          value: topic?.id,
        })) || []
      );
    });

    watch(
      () => form.category,
      () => {
        const topicsFromCategory = prettierTopicsFromCategory.value;
        if (topicsFromCategory.length) {
          form.topic = topicsFromCategory[0]?.value;
        } else {
          form.topic = "";
        }
      }
    );
    // END === Function for get list categories ==============================================

    // START === Function for get list topic ============================================
    const topics = ref([] as any[]);
    const getTopics = async () => {
      const { getAdminLearningTopics } = await servicesV1AdminLearningTopic();

      try {
        const hit = await getAdminLearningTopics();

        const resData = hit?.response;

        topics.value = resData.map((topic: ListTopic) => ({
          ...topic,
          text: topic.title,
          value: topic.id.toString(),
        }));
      } catch (err: any) {
        console.error("err getTopics ", err, err?.response);
      }
    };
    // END === Function for get list topic ==============================================

    // START === Functions handle get detail materi ==========================================
    const getDetailMateri = async () => {
      const { getAdminLearningById } = await servicesV1AdminLearning();

      gpf.gLoading.show();

      try {
        const hit = await getAdminLearningById(materiId.value.toString());

        await nextTick();

        const resData = hit.response;
        console.log("resData getDetailMateri ", resData);

        form.title = resData.title;
        form.banner.fileUrl = resData.banner;
        form.types = resData.types;

        if (resData.types === "ebook") form.content.fileUrl = resData.content;
        if (resData.types === "video") form.contentVideo = resData.content;

        form.isActive = resData.isActive ? "1" : "0";
        form.category = resData?.category?.id.toString() || "0";
        form.topic = resData?.topic?.id.toString() || "0";

        console.log("form ", form);
      } catch (err: any) {
        console.log("err response getDetailMateri ", err, err?.response);

        gpf.handleEds(err);
      } finally {
        gpf.gLoading.hide();
      }
    };

    watch(
      () => isEditPage.value,
      (newVal) => {
        if (newVal) getDetailMateri();
      }
    );
    // END === Functions handle get detail materi ============================================

    // START === Function actions =========================================================
    const onCancelClick = () => gpf.hideModal(`#${modalId}`);

    const onSaveClick = () => {
      console.log("materi ID ", materiId.value);
      console.log("materi Slug ", materiSlug.value);

      if (isEditPage.value) {
        hitEditMateri();
      } else {
        hitAddMateri();
      }
    };

    const afterClickSaveMateri = () => {
      gpf.hideModal(`#${modalId}`);
      emit("update:refetch", true);
    };

    const hitAddMateri = async () => {
      const { postAdminLearning } = await servicesV1AdminLearning();

      gpf.gLoading.show();

      const body = new FormData();
      body.append("title", form.title);
      body.append("banner", form.banner.file);

      if (form.types === "ebook") body.append("content", form.content.file);
      if (form.types === "video") body.append("content", form.contentVideo);

      body.append("types", form.types);
      body.append("isActive", form.isActive === "1" ? "1" : "0");
      body.append("category", form.category.toString());
      body.append("topic", form.topic.toString());

      try {
        await postAdminLearning(body);

        popupStatus({
          type: "s",
          message: "Successfully Created Materi",
        }).show();

        afterClickSaveMateri();
      } catch (err: any) {
        console.error("err hitAddMateri ", err, err?.response);

        const errData = err?.response?.data;
        const messageErr = errData?.diagnostic?.message;

        popupStatus({
          type: "f",
          message: messageErr,
        }).show();
      } finally {
        gpf.gLoading.hide();
      }
    };

    const hitEditMateri = async () => {
      const { putAdminLearning } = await servicesV1AdminLearning();

      gpf.gLoading.show();

      const body = new FormData();
      body.append("title", form.title);

      const fileBanner = form.banner.file;
      if (fileBanner) {
        body.append("banner", fileBanner);
      } else {
        body.append("banner", "");
      }

      const fileContent = form.content.file;
      if (form.types === "ebook" && fileContent)
        body.append("content", form.content.file);
      if (form.types === "ebook" && !fileContent) body.append("content", "");
      if (form.types === "video") body.append("content", form.contentVideo);

      body.append("types", form.types);
      body.append("isActive", form.isActive === "1" ? "1" : "0");
      body.append("category", form.category.toString());
      body.append("topic", form.topic.toString());

      try {
        await putAdminLearning(materiId.value.toString(), body);

        popupStatus({
          type: "s",
          message: "Successfully Updated Materi",
        }).show();

        afterClickSaveMateri();
      } catch (err: any) {
        console.error("err hitEditMateri ", err, err?.response);

        const errData = err?.response?.data;
        const messageErr = errData?.diagnostic?.message;

        popupStatus({
          type: "f",
          message: messageErr,
        }).show();
      } finally {
        gpf.gLoading.hide();
      }
    };
    // END === Function actions ===========================================================

    return {
      modalId,
      isEditPage,
      titleForm,
      filterTypes,
      listStatus,
      form,

      categories,
      topics,
      prettierTopicsFromCategory,

      onCancelClick,
      onSaveClick,
    };
  },
});
